<template>
    <div v-html="html"></div>
</template>

<script>
export default {
    name: "toPay",
    data(){
        return{
            orderNo: "",
            html: ''
        }
    },

    mounted() {
        var that = this;
        that.orderNo = this.$route.params.id;
        if(that.orderNo != ""){
            that.getPayInfo();
        }else{
            that.utils.err("参数错误");
        }
    },

    methods:{
        getPayInfo(){
            var that = this;
            that.newApi.checkCorpOrder({orderNo: that.orderNo, type: that.$route.query.type}).then((res) => {
                if(res.isSuccess == 1){
                    that.html = res.data.transAssembledUrl;
                    setTimeout(function () {
                        document.getElementById("theForm").submit()
                    },1000);
                }
            })
        }
    }
}
</script>

<style scoped>

</style>